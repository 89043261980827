<template>
  <div class="main">
    <div class="top-wrap solution-top-back">
      <!-- <div class="top-mask"></div> -->
      <div class="top-box">
        <div class="top-box-body">
          <h3 class="top-box-title">腾讯云全栈解决方案</h3>
          <div class="top-box-desc">
            <p>
              腾讯云面向丰富的业务场景，提供整套行业和技术解决方案，助力客户安全高效上云。
            </p>
          </div>
          <a href="https://cloud.tencent.com/solution" class="top-box-btn">查看全部解决方案</a>
        </div>
      </div>
    </div>

    <div class="solution-wrap">
      <div class="solution-box">
        <div class="solution_top">
          <div class="solution_tit">
            腾讯云热门解决方案
          </div>
          <div class="solution_p">
            腾讯云面向丰富的业务场景，提供整套行业和技术解决方案，助力客户安全高效上云。
          </div>
        </div>

        <div :class="'solution_item '+item.backClass" v-for="item,index in solutions" v-bind:key="index">
          <div class="solution_item_box">
            <h3 class="solution_item_tit">{{item.solutionName}}</h3>
            <p class="solution_item_p">
              {{item.desc}}
            </p>
            <a :href="item.href" class="solution_item_btn"> 点击查看 </a>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// 头部导航选中
export default {
  name: "recruit",
  data() {
    return {
      solutions: require("../../static/json/solution/solutionData.json")
    };
  },
  mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("../assets/css/public.css");
.solution-top-back {
  background-image: url("https://imgcache.qq.com/omp/public/PC.b9b5614b.jpg");
  background-size: 110% 100%;
  /* background-position-y: -100px; */
}

.solution-wrap{
  width: 100%;
  overflow: hidden;
}
.solution-box{
  margin: 0  auto;
  width: 1200px;
  padding: 20px 0;
}
.solution_top{
  text-align: center;
  padding: 20px 0;
}
.solution_tit{
  padding: 0 0;
  font-weight: 500;
    font-size: 28px;
    color: #000;
    line-height: 48px;
}

.back_slt1 {
  background-size: 120% 100%;
  background-repeat: no-repeat;
  background-image: url("https://main.qcloudimg.com/raw/8be69dd6970ca2f96ad9d41bd46ee2e0.png");
}
.back_slt2 {
  background-size: 120% 100%;
  background-repeat: no-repeat;
  background-image: url("https://main.qcloudimg.com/raw/0f59d0e180cc27534b0932603fc39c06.jpg");
  text-align: right;
}
.back_slt3 {
  background-size: 120% 100%;
  background-repeat: no-repeat;
  background-image: url("https://main.qcloudimg.com/raw/5326b98644db6ef2300938efe87dfd12.jpg");
}
.back_slt4 {
  background-size: 120% 100%;
  background-repeat: no-repeat;
  background-image: url("https://main.qcloudimg.com/raw/746432719c038a2efeebfc73a3aa0694.jpg");
  text-align: right;
}
.back_slt5 {
  background-size: 120% 100%;
  background-repeat: no-repeat;
  background-image: url("https://main.qcloudimg.com/raw/590bd93d876370c39d2ec682117004e0.jpg");
}
.back_slt6 {
  background-size: 120% 100%;
  background-repeat: no-repeat;
  background-image: url("https://qcloudimg.tencent-cloud.cn/raw/5a113338d3cc5e90beeb4f20d8caa689.jpg");
  text-align: right;
}
.back_slt7 {
  background-size: 120% 100%;
  background-repeat: no-repeat;
  background-image: url("https://qcloudimg.tencent-cloud.cn/raw/9fdf636f430ba7ea8ac8399b01c3f90f.jpg");
}
.solution_item_box{
  height: 200px;
  padding: 15px 40px;
  margin-bottom: 20px;
}
.solution_item_tit {
  font-weight: 500;
  font-size: 30px;
  color: #fff;
  padding: 20px 0;
}
.solution_item_p {
  line-height: 26px;
  font-size: 16px;
  color: #fff;
  padding: 14px 0;
}
.solution_item_btn{
  background-color: #0092ee;
  color: #fff;
  padding: 10px 36px;
}
</style>
